// src/Section2.js
import React from 'react';
import { Container} from 'react-bootstrap';
import './Section2.css';
import image1 from './logo.svg'; 



const Section2 = () => {
  return (
    <Container className="section2 my-5" id='tokenomics'>
      <div className="tokenomics">
      <h1>Tokenomics</h1>
          <br></br>
          <h3>$RTRD will be launched on BaseJump for the first phase. After that, $RTRD will be tradable on uniswap, on Base network.</h3>
      <div className="tokenomics-content">
        <img src={image1} alt="" className="logoTokenomics" />
      </div>
      <div className="tokenomics-info">
        <div className="info-box">0/0 TAX</div>
        <div className="info-box">100.000.000 SUPPLY</div>
        <div className="info-box">BASE</div>
      </div>
      <div className="contract-address">
        <p>CONTRACT ADDRESS</p>
        <p>0x0c1Ce60b4B7Bf832a5799e8C3180E4A7396e46E1</p>
      </div>
    </div>
    </Container>
  );
};

export default Section2;
