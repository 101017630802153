// src/Section1.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Section1.css'; 
import retired from './retired.svg'; 

const Section1 = () => {
  return (
    <Container className="section1 my-5" id='overview'>
      <Row className="align-items-center">
        <Col lg={6} md={12} className="text-section">
          <h1 className='bort-title vibration'>RETIRED</h1>
          <p>Brett is retired. He's given so much to the community, it's time to return the favor.</p>
        </Col>
        <Col lg={6} md={12} className="image-section text-center">
          <img src={retired}  alt="Decorative" className="img-fluid retired" />
        </Col>
      </Row>
    </Container>
  );
};

export default Section1;
