// src/Section4.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Section3.css'; 
import twitterSvg from './twitter.png'; 
import tgSvg from './tg.png'; 


const Section3 = () => {
  return (
    <Container className="section4 text-center" id='joinus'>
      <Row>
        <Col>
        <h1 className="section4-title">Join us</h1>
          <p className="section4-text">
          Retired Brett is ready to celebrate his retirement day!
        </p>

        <div className="tokenomics-content">
        <a href="https://x.com/retiredbrett" target="_blank" rel="noopener noreferrer">
      <img src={twitterSvg} alt="Twitter" className="logoJoin" />
      </a>
      <a href="https://t.me/retiredbrett" target="_blank" rel="noopener noreferrer">
        <img src={tgSvg} alt="Telegram" className="logoJoin" />
      </a>
      </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Section3;
